<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true">
      <el-form-item label="字典类型" prop="type">
        <el-input
          v-model="queryParams.type"
          placeholder="请输入字典类型"
          disabled
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="字典标签" prop="label">
        <el-input
          v-model="queryParams.label"
          placeholder="请输入字典标签"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="数据状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions.values"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <vxe-grid
      resizable
      ref="xGrid"
      stripe
      show-overflow
      highlight-hover-row
      :loading="loading"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
    >
      <template #toolbar_buttons>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增</el-button
        >
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          >修改</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          >删除</el-button
        >
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }">
        <el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleUpdate(row, true)"
        >查看
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          >修改
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
        <span>
          <p>暂无数据</p>
        </span>
      </template>
    </vxe-grid>
    <vxe-pager
      background
      size="small"
      :loading="loading"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="total"
      @page-change="handleSizeChange"
    >
    </vxe-pager>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="500px"
      :close-on-click-modal="false"
      append-to-body
    >
      <jf-form ref="form" :model="form" :rules="rules" label-width="100px" :readonly="readonly" :readonly-column="1">
        <el-form-item label="字典类型">
          <el-input v-model="form.type" :disabled="true" />
        </el-form-item>
        <el-form-item label="数据标签" prop="label">
          <el-input v-model="form.label" placeholder="请输入数据标签" />
        </el-form-item>

        <el-form-item label="数据键值" prop="value">
          <el-input v-model="form.value" placeholder="请输入数据键值" />
        </el-form-item>

        <el-form-item label="标签字体颜色" prop="label_color">
          <el-color-picker
            :predefine="predefineColors"
            show-alpha
            v-model="form.label_color"
          ></el-color-picker>
        </el-form-item>

        <el-form-item label="显示排序" prop="sort">
          <el-input-number
            v-model="form.sort"
            controls-position="right"
            :min="0"
          />
        </el-form-item>

        <el-form-item label="系统默认">
          <el-switch
            v-model="form.is_default"
            active-text="是"
            inactive-text="否"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions.values"
              :key="dict.key"
              :label="dict.key"
              >{{ dict.value }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </jf-form>
      <div slot="footer" class="dialog-footer">
        <template v-if="!readonly">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </template>
        <template v-else>
          <el-button @click="cancel">关 闭</el-button>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listData,
  getData,
  delData,
  addData,
  updateData,
  exportData,
} from "@/api/core/data/dict/data";

export default {
  name: "core-data-dict-datalist",
  data() {
    return {
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "#c7158577",
      ],

      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 列
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left" },
        { field: "id", title: "字典编码", width: 100, fixed: "left", visible: false },
        {
          field: "label",
          title: "字典标签", 
          slots: {
            // 使用 JSX 渲染
            default: ({ row }) => {
              let myStyle = {
                color: row.label_color,
              };
 
              return [<span style={myStyle}>{row.label}</span>];
            },
          },
        },
        { field: "value", title: "字典键值" },
        { field: "sort", title: "字典排序" },
        {
          field: "status",
          title: "状态",
          width: 100,
          formatter: this.statusFormat,
        },
        { field: "remark", title: "备注", width: 180 },
        {
          field: "create_time",
          title: "创建时间",
          width: 180,
          formatter: "formatDate",
        },
        {
          field: "",
          title: "操作",
          resizable: false,
          width: 180,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
        },
      ],

      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 字典表格数据
      dataList: [],
      // 默认字典类型
      defaultDictType: "",
      // 弹出层标题
      title: "",
      // 是否查看
      readonly: false,
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 类型数据字典
      typeOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: undefined,
        type: undefined,
        status: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        label: [
          { required: true, message: "数据标签不能为空", trigger: "blur" },
        ],
        value: [
          { required: true, message: "数据键值不能为空", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "数据顺序不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    const type = this.$route.params && this.$route.params.dictType;
    this.queryParams.type = type;
    this.defaultDictType = type;
    this.getList();
  },
  methods: {
    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },
    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    /** 查询字典数据列表 */
    getList() {
      this.loading = true;
      listData(this.queryParams).then((response) => {
        this.single = true;
        this.multiple = true;
        this.dataList = response.data.list;
        this.statusOptions = response.data.searchStatus;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    // 数据状态字典翻译
    statusFormat({ cellValue }) {
      return this.selectDictLabel(this.statusOptions.values, cellValue);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        type: undefined,
        label: undefined,
        value: undefined,
        sort: 0,
        is_default: "0",
        status: "1",
        remark: undefined,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams.type = this.defaultDictType;
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.readonly = false;
      this.title = "添加字典数据";
      this.open = true;
      this.form.type = this.queryParams.type;
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 修改按钮操作 */
    handleUpdate(row, readonly) {
      this.reset();
      const id = row.id || this.ids;
      getData(id).then((response) => {
        this.form = {
          type: response.data.type,
          id: response.data.id,
          label: response.data.label,
          value: response.data.value,
          label_color: response.data.label_color,
          sort: response.data.sort,
          is_default: "" + response.data.is_default,
          status: "" + response.data.status,
          remark: response.data.remark,
        };
        this.readonly = readonly;
        this.title = readonly ? "查看字典数据": "修改字典数据";
        this.open = true;
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateData(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addData(this.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const dict_ids = row.id || this.ids.join(',');
      this.$confirm(
        '确定删除吗?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(function () {
          return delData(dict_ids);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm("是否确认导出所有数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return exportData(queryParams);
        })
        .then((response) => {
          this.download(response.msg);
        })
        .catch(function () {});
    },
  },
};
</script>
